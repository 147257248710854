*{
   box-sizing: border-box;
}


body{
   background-color: #EFEADD;
}


html{
   font-size:16px;
}