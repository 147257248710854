
.App{

text-align: center;
background-color: #EFEADD;
}


.preloderGeneral{
    height: 100vh;
    display:flex;
    justify-content: center;
    align-items: center;
}